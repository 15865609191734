<template>
  <div>
    <el-card
      class="box-card"
      shadow="never"
      :style="{ height: cardHeight + 'px' }"
    >
      <el-row>
        <el-col :span="4">
          <span style="margin-left: 11px"> {{ title }} </span>
        </el-col>
        <el-col :span="20">
          <div style="float: right">
            <el-input
              v-if="showKeyword"
              placeholder="请输入内容"
              v-model="keyword"
              clearable
              :size="allSize"
              style="width: 150px; margin-right: 2px"
              @input="inputAllKeyword"
              @clear="clearKeyword"
            >
            </el-input>
            <el-tooltip
              class="item"
              effect="dark"
              content="搜索"
              placement="bottom"
            >
              <el-button
                v-if="!showKeyword"
                icon="el-icon-search"
                type="primary"
                circle
                :size="allSize"
                @click="openKeyword"
              ></el-button>
            </el-tooltip>
            <!--选中筛选条件组合--->
            <el-tooltip
              v-if="!isfilter"
              class="item"
              effect="dark"
              content="筛选"
              placement="bottom"
            >
              <el-popover
                placement="left-start"
                width="380"
                trigger="manual"
                v-model="showWhere"
              >
                <filter-view
                  @oncloseWhere="oncloseWhere"
                  @onSeachData="onSeachData"
                  :url="url"
                  :isfilter="isfilter"
                ></filter-view>
                <el-button
                  type="primary"
                  icon="el-icon-s-cooperation"
                  circle
                  slot="reference"
                  :size="allSize"
                  @click="showFitlers"
                  style="margin-right: 10px"
                ></el-button>
              </el-popover>
            </el-tooltip>
            <el-tag
              closable
              @close="closeFilter"
              slot="reference"
              v-if="isfilter"
              >{{ whereTitle }}</el-tag
            >
           
          </div>
        </el-col>
      </el-row>

      <!----表单内容---->
      <el-row class="top">
        <el-table
          :data="list"
          :size="allSize"
          :height="tableHeight"
          :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
           style="width: 100%"
           border
           v-loading="loading"
        >
          
          <el-table-column prop="userinfo.username" label="用户名"></el-table-column>
          <el-table-column prop="userinfo.telephone" label="手机号"></el-table-column>
          <el-table-column prop="money" label="充值积分"></el-table-column>
          <el-table-column prop="create_time" label="充值时间"></el-table-column>

          <!-- <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="openorder(scope.row.member_id)">查看订单</el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <div class="pagination">
          <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" 
            :current-page="page"
            :page-size="size"
            :total="total">
            </el-pagination>
        </div>
      </el-row>
    </el-card>

    <div class="container-box">
      <el-dialog title="新增内容" :visible.sync="showCreate" :close-on-click-modal="false" :close-on-press-escape="false" class="fansdialog" top="10vh" width="80%">
        <div class="el-dialog-div">
          <el-form ref="formData" :model="formData" :rules="rules" :label-width="formLabelWidth">
            <el-form-item label="标题" prop="title">
              <el-col :span="10">
                <el-input v-model="formData.title" autocomplete="off" :size="allSize"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="图片" prop="pic_path" style="width: 100%" >
                  <el-col :span="10">
                    <div v-if="!formData.pic_path">
                      <el-row style="background: rgba($color: #000000, $alpha: 0.4)">
                        <img src="@/assets/img/image_upload.png" style="width: 100px; height: 100px" @click="openImage('pic_path', 'one')"/>
                      </el-row>
                    </div>
                    <div class="imgDiv" v-else>
                      <el-image :src="formData.pic_path" style="width: 100px; height: 100px" :preview-src-list="[formData.pic_path]">
                      </el-image>
                      <el-row style="text-align: center">
                        <el-button @click.native.prevent="removeImage('pic_path', 'one')" type="text">
                          移除
                        </el-button>
                      </el-row>
                    </div>
                  </el-col>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="showCreate = false" :size="allSize">取 消</el-button>
          <el-button type="primary" @click="saveData()" :size="allSize">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="请选择图片"  width="80%" :visible.sync="showimage" :close-on-click-modal="false">
        <select-image @onSelectImage="onSelectImage" :imagetype="imagetype"></select-image>
      </el-dialog>
       <!-- <el-dialog title="订单详情"  width="80%" :visible.sync="showorder" :close-on-click-modal="false">
             <el-table :data="mingxilist" style="width: 100%" v-loading="loading" height="300" size="mini">
                  <el-table-column prop="order_num_alias" label="订单编码"></el-table-column>
                  <el-table-column prop="total" label="订单金额"></el-table-column>
                  <el-table-column prop="name" label="员工名称"></el-table-column>
             </el-table>
      </el-dialog> -->
    </div>
  </div>
</template>

<script>
let _this;
import selectImage from "@/components/image/image.vue";
import EditorBar from "@/components/wangEnduit.vue";
import filterView from "@/components/filterView.vue";
export default {
  name: "Fuwu",
  components: { selectImage, EditorBar, filterView },
  computed: {
    cardHeight() {
      return window.innerHeight * 1 - 130;
    },
    tableHeight() {
      return window.innerHeight * 1 - 130 - 120;
    },
  },
  data() {
    return {
      showimage: false,
      selectField: "",
      list: [],
      isClear: true,
      total: 0,
      imagetype: "one",
      url: "/Liushui/",
      title: "流水",
      allSize: "mini",
      loading: false,
      showKeyword: false,
      showWhere: false,
      showCreate: false,
      whereTitle: "",
      isfilter: false,
      whereArray: [],
      page: 1,
      size: 20,
      keyword: "",
      formData: "",
      formLabelWidth: "120px",
      rules: {
        title: [
          {
            required: true,
            message: "请填写标题",
            trigger: "blur",
          },
        ],
      },

      showorder:false,
      orderlist:[],
      ordertotal:0,
      orderpage:1,
      ordersize:20
    };
  },
  mounted() {
    _this = this;
    _this.getData();
    _this.keyupSubmit();
  },
  methods: {
    openorder(member_id){
        var form = new FormData();        
        form.append('member_id', member_id);
      
        _this.postRequest(
        _this.url + "getuserorderlist?page=" + _this.orderpage + "&size=" + _this.ordersize,
        form
        )
        .then((resp) => {
            _this.orderlist = resp.data;
            _this.ordertotal = resp.count;
            _this.showorder = true
        })
        .catch((e) => {
            _this.$message({
                type: "error",
                message: e.message,
            });
        });
    },

    editData(id) {
      _this.formData = { title: "", pic_path:"" };
      if (id != 0) {
        _this.getInfo(id);
      }
      _this.showCreate = true;
    },
    
    getData() {
      _this.loading = true;

      var form = new FormData();        
      form.append('keyword', this.keyword);
      form.append('whereArray', this.whereArray);
      _this.postRequest(
          _this.url + "index?page=" + _this.page + "&size=" + _this.size,
          form
        )
        .then((resp) => {
          _this.list = resp.data;
          _this.total = resp.count;
          _this.loading = false;
        })
        .catch((e) => {
          _this.$message({
            type: "error",
            message: e.message,
          });
        });
    },


    getInfo(id) {
      _this.getRequest(_this.url + "read?id=" + id).then((resp) => {
        if (resp) {
          _this.formData = resp.data;
        }
      });
    },
    
    saveData() {
      _this.$refs["formData"].validate((valid) => {
        if (valid) {
          _this.postRequest(_this.url + "save", _this.formData).then((resp) => {
            if (resp.code == 200) {
              _this.$message({
                type: "success",
                message: resp.msg,
              });
              _this.getData();
              _this.showCreate = false;
            } else {
              _this.$message({
                type: "error",
                message: resp.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    delData(index, id) {
      _this
        .$confirm("是否删除该信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _this.deleteRequest(this.url + "delete?id=" + id).then((resp) => {
            if (resp.code == 200) {
              _this.$message({
                type: "success",
                message: "删除成功!",
              });
              _this.list.splice(index, 1);
            } else {
              _this.$message({
                type: "error",
                message: resp.msg,
              });
            }
          });
        })
        .catch(() => {
          _this.$message({
            type: "error",
            message: "取消删除!",
          });
        });
    },

    handleFieldChange(field, value, id, type) {
      var data = { field: field, value: value, id: id };

      _this.postRequest(_this.url + "changeField", data).then((resp) => {
        if (resp.code == 200) {
          this.$notify({
            title: "成功",
            message: resp.msg,
            type: "success",
          });
        } else {
          _this.$notify({
            title: "错误",
            type: "error",
            message: resp.msg,
          });
        }
      });
    },

    /**操作筛选条件start */
    oncloseWhere() {
      _this.showWhere = false;
    },
    closeFilter() {
      _this.showWhere = false;
      _this.isfilter = false;
      _this.whereArray = [];
      _this.getData();
    },
    clearKeyword(value) {
      this.showKeyword = false;
    },

    openKeyword() {
      this.showKeyword = true;
    },

    inputAllKeyword(keyword) {
      _this.keyword = keyword;
      _this.whereArray = [];
      _this.page = 1
      _this.getData();
    },

    showFitlers() {
      _this.showKeyword = false;
      _this.keyword = "";
      _this.showWhere = !this.showWhere;
    },

    onSeachData(value, isfilter) {
      if (isfilter) {

        _this.isfilter = true;
        _this.whereTitle = value.title;
        _this.whereArray = value.whereArray;
        _this.showWhere = false;
      } else {
        _this.whereArray = value;
      }
      _this.page = 1
      _this.getData();
    },

    handleSizeChange(val) {
      _this.size = val;
      _this.getData();
    },
    handleCurrentChange(val) {
      _this.page = val;
      _this.getData();
    },
    /**操作筛选条件end */

    /**操作图片方法start */
    onSelectImage(value) {
      if (_this.imagetype == "more") {
        value.forEach((item) => {
          _this.formData[_this.selectField].push(item);
        });
      } else {
        console.log(value);
        _this.formData[_this.selectField] = value;
      }

      _this.showimage = false;
    },
    openImage(field, imagetype) {
      _this.showimage = true;
      _this.imagetype = imagetype;
      _this.selectField = field;
    },
    removeImage(field, imagetype, index) {
      if (imagetype == "one") {
        _this.formData[field] = "";
      } else {
        _this.formData[field].splice(index, 1);
      }
    },
    /**操作图片方法end */
    //监听键盘
    keyupSubmit() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        //13 回车
        if (_key === 13) {
          console.log(this.search.keyword);
        }
      };
    },
  },
};
</script>

<style lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.top {
  margin-top: 10px;
}

.el-dropdown-link:hover {
  color: #409eff;
}

.select_input {
  border: none !important;
  box-shadow: none !important;
  padding: 0px;
}

/* switch按钮样式 */
.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}
/*打开时文字位置设置*/
.switch .el-switch__label--right {
  z-index: 1;
}
/* 调整打开时文字的显示位子 */
.switch .el-switch__label--right span {
  margin-right: 9px;
}
/*关闭时文字位置设置*/
.switch .el-switch__label--left {
  z-index: 1;
}
/* 调整关闭时文字的显示位子 */
.switch .el-switch__label--left span {
  margin-left: 5px;
}
/*显示文字*/
.switch .el-switch__label.is-active {
  display: block;
}
/* 调整按钮的宽度 */
.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
.imgDiv {
  display: inline-block;
  position: relative;
}

.imgDiv .delete {
  position: absolute;
  top: -4px;
  left: 75px;
  right: 0px;
  width: 25px;
  height: 25px;
  display: none;
}
.el-dialog-div {
  height: 60vh;
  overflow: auto;
}

.dropdown {
  cursor: pointer;
}
.drop {
  padding: 10px;
}
.input {
  width: 100px;
  margin: 0 auto;
  width: 100%;
}

.project-dropdown {
  //设置高度才能显示出滚动条 !important
  height: 300px;
  overflow: auto;
}
.project-dropdown::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
.project-dropdown::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.pic {
  width: 50px;
  height: 50px;
}

.imgDiv {
  display: inline-block;
  position: relative;
}

.imgDiv .delete {
  position: absolute;
  top: -4px;
  left: 75px;
  right: 0px;
  width: 25px;
  height: 25px;
  display: none;
}
.el-dialog-div {
  height: 60vh;
  overflow: auto;
}

.dropdown {
  cursor: pointer;
}
.drop {
  padding: 10px;
}
.input {
  width: 100px;
  margin: 0 auto;
  width: 100%;
}

.project-dropdown {
  //设置高度才能显示出滚动条 !important
  height: 300px;
  overflow: auto;
}
.project-dropdown::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
.project-dropdown::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.pic {
  width: 50px;
  height: 50px;
}

.el-table tr td.notwriting {
  background: #b5b4b3;
}
.chosen {
  border: solid 1px #3089dc !important;
}
</style>
